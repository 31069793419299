import Home from "./pages/Home";
import Footer from "./pages/Footer";
import About from "./pages/About"
import Tracking from "./pages/Tracking";
import Navbar from "./pages/Navbar";
import { Route, Routes } from "react-router-dom"
import Product from "./pages/FabricCat";
import FabricDetail from './pages/FabricDetail';

export default function App() {

  return (
    <>
      <Navbar />
      <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/product/:fabricId" element={<FabricDetail />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/product" element={<Product />} />
          <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </>
  );
}