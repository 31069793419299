import { animateScroll as scroll } from 'react-scroll'

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3">
                <h1 className="logo">YUEN CHEN TRADING</h1>
                <p className="footer-text">
                  Wrap Yourself in Comfort: Where Fabric and Satisfaction Meet! ™
                </p>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row">
              <div className="col-md-3">
                <p className="footer-title">Contact</p>
                <ul>
                  <li>
                    <a target='_blank'
                      rel='noopener noreferrer' 
                      href="mailto:yuenchentrading@hotmail.com">yuenchentrading@hotmail.com
                    </a>
                  </li>
                  <li>
                    <a target='_blank'
                      rel='noopener noreferrer' 
                      href="https://wa.me/+60123352184">+60 12-3352184
                    </a>
                  </li>
                  <li>
                    <a target='_blank'
                      rel='noopener noreferrer' 
                      href="https://wa.me/+60173352184">+60 17-3352184
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Social Media</p>
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/yctfabric" > Instagram</a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/YCTfabric" > Twitter</a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/koh-kai-sheng-80bb50240/"> Linkedin</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>  
      </div>
      <p className="textp  ower">Design & Development by YCT</p>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop"><i className="fas fa-level-up-alt"></i></button>

    </footer>
  );
}
export default Footer;
