export default function About() {
  const isMobile = window.innerWidth < 768;
  let string =  
              `Yuen Chen Trading, established in 1989, is on a mission to supply top-quality textiles and fufilling our responbility to the industry.
              Their vision revolves around innovation and sophistication in the realm of sofa fabrics. YCT recognizes that these 
              fabrics play a pivotal role in enhancing both aesthetics and comfort within living spaces.`
  let string2 =
              `Our commitment to quality
              craftsmanship shines through in their meticulously curated range of fabrics, which caters to diverse customer preferences.
              Whether you lean toward classic elegance or modern chic, YCT has you covered. We source only the finest materials, 
              ensuring that their fabrics are not just visually stunning but also durable and functional.` 
  let string3=      
              `YCT prioritizes personalized consultations and efficient delivery services, making the entire journey from selection to 
              installation a seamless experience for customers. 
              So, if you’re seeking luxury that meets functionality and style that meets substance, 
              Yuen Chen Trading invites you to explore their world of possibilities.`
  return(     
  <>
  <div style={{marginTop:'4em',width:'100%'}} className="about-scroll"></div>
        <div className="container-Title">
        <h3 className="main-title about-h2">ABOUT US</h3>
        </div>
        <div className="container-about">
        {isMobile ? (        
           <div className="row">
            <div className="col-md-6-about text-center">
            <img src = "./img/logo.png" alt = 'test' width="150" height="150"></img>
             <p className="main-p"> 
                {string}<br /><br />{string2}<br /><br />{string3}
              </p>   
             </div></div>
            ):(
            <div className="row-about">
            <p className="main-p">
              {string}<br /><br />{string2}<br /><br />{string3}
            </p>  
            <img src = "./img/logo.png" alt = 'test' width="300" height="300"></img>
            </div>
                )}
        </div>
      </>
      );
      }

 