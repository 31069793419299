import { Link, useLocation, useResolvedPath} from "react-router-dom";
// import './navbar.css'

// import { useState } from "react"

export default function Navbar() {
  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 768 && window.scrollY > 100) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");
      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else if (window.screen.width > 768 && window.scrollY > 100) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else {
      const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      nav.classList.remove("navopened");
    }
  });
  function openBar() {
    const bar = document.querySelector(".bar");
    bar.classList.toggle("opened");
    
  }

  return (
    <nav className={"navbar"}>
      <div className="container">
        <div className="row">
          <h1 className="logo">
            <Link spy={true} smooth={true} duration={1000} to="/" style={{ cursor: 'pointer' }}>
              YCT FABRIC
            </Link>
          </h1>
          <ul className="bar">
            <li>
              <CustomLink to="/about" activeClass="active" onClick={openBar}>About</CustomLink>
            </li>
            <li>
              <CustomLink to="/"activeClass="active"onClick={openBar}>Home</CustomLink>
            </li>
            <li>
              <CustomLink to="/product"activeClass="active"onClick={openBar}>Product</CustomLink>
            </li>
            <li>
              <CustomLink to="/tracking" activeClass="active"onClick={openBar}>Tracking</CustomLink>
            </li>
          </ul>
          <div className="button" onClick={openBar}>
            <div className="burger"></div>
            <div className="burger"></div>
            <div className="burger"></div>
          </div>
        </div>
      </div>
    </nav>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const location = useLocation();
  const isActive = location.pathname === resolvedPath.pathname;
  // const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ''}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}