import './productCat.css'
import productData from './productData.json'

export default function Product() {
  const ztf = productData['FabricCat'];
  return (
    <>
    <div style={{marginTop:'4em',width:'100%'}}className="product-scroll"></div>
    <div className="container-Title">
    <h3 className="main-title fabricCat-h3">Fabric Category</h3>
    </div>
    <div className="container123" >
      {ztf.map(ztfData => {
        return(
          <a href={"product/Yct"+String(ztfData.Title).slice(0,5)}>
          <div className='grid-item321' >
            <img src ={ztfData.img} alt = 'test' width="200" height="330"></img>
                <p> {ztfData.Title}</p>
            </div></a>
        )
      })}
    </div>
      </>
    );
  }