import './tracking.css'
export default function Tracking() {
    return (
      <>
      <div style={{marginTop:'8rem'}} ></div>
      <div class="textpower">
      <img src ='./img/construction.png' alt = 'constructing' class='center'></img>
      </div>
      </>
    );
  }
  