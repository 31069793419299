import Card from "./Card";
import testvideo from "../YCTVIDEO.mp4"
import React from 'react';

export default function Home() {
    return(
    <>
    <header>
         <div onContextMenu={e => e.preventDefault()} style={{ width: "100%" }}>
            {/* <video src={testvideo} 
            type={"video/mp4"}
            muted
            autoPlay
            controls={false}
            preLoad="auto"
            loop /> */}
            
            <div
            dangerouslySetInnerHTML={{
                    __html: `<video className="app__backgroundVideo" autoplay loop muted playsinline>
                    <source src=${testvideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>`,
                        }}
            />
            <div className="row">
            </div></div>
            <div className="headerbg"></div> 
            <div className="header-img">
            <img src = "./img/logo.png" alt = 'test' width="250" height="250"></img></div>
    </header>
    <div className="container services">
    <h2 className="main-title text-center">SERVICES</h2>
    <div className="card-cover">
        <div className="col-md-12">
            <div className="row">
                <div className="col-md-4 mb-2">
                    <Card title="Supply Sofa Fabric" img="../card1.png" text={
                <ul>
                    <li>Features a diverse palette of colors, patterns, and textures</li>
                    <li>From classic neutrals to bold contemporary prints</li>
                    <li>Each fabric is chosen to inspire and enhance your furniture offerings.</li>
                </ul>
                }/>
                </div>
                <div className="col-md-4 mb-2">
                <Card title="Fabric Quality Assurance" img="../card2.jpeg" text={
                <ul>
                    <li>Partner with reputable manufacturers and artisans</li>
                    <li>Ensure industry standards are met</li>
                    <li>Inspection is done before delivery</li>
                </ul>
                }/>
                </div>
                <div className="col-md-4 mb-2">
                <Card title="Customized Business Solutions" img="../card3.jpeg" text = {
                <ul>
                    <li>Options for bulk orders and custom designs</li>
                    <li>Personalized service to align with business goal</li>
                    <li>Robust system for timely delivery</li>
                    <li>Streamlined process from order to fulfillment</li>
                </ul>
                }/>
                </div>
                <div className="col-md-4 mb-2">
                <Card title="System Design & Development" img="../card4.jpeg" text={
                <ul>
                    <li>Provide system design to improve operating efficiency</li>
                    <li>Personalized system align with business goal</li>
                    <li>Thrive to digitalize and provide useful data insight</li>
                </ul>
                }/>
                </div>
            </div>
        </div>
    </div>
    </div>
    </>
    );
}