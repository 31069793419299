// import PlaceCard from "./ProductCard"
import './productCat.css'
import productData from './productData.json'
import { useParams } from 'react-router-dom';

export default function Yct66026() {
  const isMobile = window.innerWidth < 768;
  const { fabricId } = useParams();
  const ztf = productData[fabricId];
  return (
    <>
    <div style={{marginTop:'4rem',width:'100%'}}className="product-scroll"></div>
    <div className="container-Title">
    <h3 className="main-title fabricCat-h3">{fabricId.slice(3,)} SERIES</h3>
    </div>
    <div className="container123">
      {ztf.map(ztfData => {
        return(
          <div onContextMenu={e => e.preventDefault()} style={{ width: "100%" }}>
          <div className='grid-item321'>
            {isMobile ? (
                <img src ={ztfData.imgMobile} alt = 'MobileImage'  width="372" height="200"></img>
                ):(
                <img src ={ztfData.img} alt = 'DesktopImage' width="300" height="300"></img>)}
                <p> {ztfData.Title}</p>
            </div>
            </div>
        )
      })}

    </div>
      </>
    );
  }